.button-container {
    text-align: center;
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    /* max-width: ; */
    
  }
  
  .dark-mode .button-link{
    background-color: #D9D9D9;
  }

  .button-link {
    text-decoration: none;
    border-radius: 10px;
    border: 2px solid #000;
    padding: 20px;
    margin: 10px;
    transition: all 0.5s ease;
    /* max-width: 200px; Limit button width */
    display: flex; /* Make it a flex container */
    flex-direction: column; /* Align items vertically */
    align-items: center; /* Center items horizontally */
    /* max-width: 130px; */
    /* max-height: 130px; */
    width: 130px;
    height: 130px;
  
  }
  
  .button-link:hover {
    background-color: #f0f0f0; 
    transform: translateY(5px);
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.5); 
  }
  
  .button-link img {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    margin-bottom: 5px;
    justify-content: center;
    
  }

  .button-link img:hover {
    transform: translateY(5px);
    box-shadow: 0px 5px 10px rgba(0, 0, 0, 0.2); 
  }
  
  /* .button-label {
    color: #605746;
  } */


  .button-label {
    color: #605746;
    display: inline-block;
    padding: 10px 20px;
    /* background-color: #4CAF50; */
    border: none;
    text-align: center;
    bottom: 50%;
    text-decoration: none;
    font-size: 16px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
    transition: all 0.3s ease;
}

.button-label:hover {
    background-color: #D9CDBF; 
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.5);
    transform: translateY(-2px);
}

.button-label:active {
    background-color: #D9CDBF;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    transform: translateY(1px);
}

@media screen and (max-width: 768px) {
    .button-container {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      gap: 0px; /* Reduced gap between buttons */
      justify-items: center; /* Center the buttons horizontally */
      margin: 0 5px; /* Margin for left and right sides */
    }
  
    .button-link {
      width: 50%; /* Adjusted width for smaller screens with reduced gap and margin */
    }
  }



/*  */

/* .footer {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background-color: #f0f0f0;
  padding: 10px 0;
  text-align: center;
} */



  
