body {
    font-family: Arial, sans-serif;
    margin: 0;
    padding: 0;
    background-color: #f8f8f8;
  }
  
  .container {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  h1 {
    text-align: center;
    color: #333;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .label {
    display: block;
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
    color: #333;
  }
  
  .select {
    width: 100%;
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
  }
  
  .rankings {
    margin-top: 20px;
  }
  
  .rankings h2 {
    margin-bottom: 10px;
    font-size: 20px;
    color: #333;
  }
  
  table {
    width: 100%;
    border-collapse: collapse;
    border-radius: 5px;
    overflow: hidden;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.1);
  }
  
  thead {
    background-color: #007bff;
    color: #fff;
  }
  
  th, td {
    padding: 12px 15px;
    text-align: left;
  }
  
  th {
    font-weight: bold;
  }
  
  tbody tr:nth-child(even) {
    background-color: #f2f2f2;
  }
  
  tbody tr:hover {
    background-color: #e0e0e0;
  }
  