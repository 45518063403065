body {
  font-family: Arial, sans-serif;
  background: linear-gradient(180deg, #F2EFED 0%, #F8F6F6 100%);
  margin: 0;
  padding: 0;
}

.dark-mode body {
  background: #000;
  color: #f8f8f8;
  margin: 0;
  padding: 0;
  height: 100%;
}

.app-title {
  text-align: center;
  margin-top: 20px;
  color: #333;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.1);
}

.dark-mode .app-title{
  color: #000;
}

.app-container {
  background: linear-gradient(180deg, #F8F6F6 0%, #F2EFED 100%);
  padding: 20px;
}

.dark-mode .app-container {
  background: #000; /* Dark mode background color for app container */
}

.app-header {
  border: 2px solid #D9D9D9;
  border-radius: 10px;
  padding: 10px;
  background-color: #D9D9D9;
}

.dark-mode .app-header {
  border-color: #666; /* Border color for dark mode */
  background-color: #ccc5b9; /* Header background color for dark mode */
  color: #f8f8f8;;
}


.app-title {
  text-align: center;
  margin: 0;
  color: #333;
}

.app-logo {
  width: 50px;
  margin-right: auto;
  margin-left: auto;
  display: block;
}

.circular-button {
  display: inline-block;
  background-color: #f1d0d0;
  color: white;
  font-size: 16px;
  font-weight: bold;
  text-align: center;
  padding: 15px 30px;
  border-radius: 50%;
  text-decoration: none;
  transition: background-color 0.3s;
}

.circular-button:hover {
  background-color: #f4c4c4;
}

.flex-container {
  display: flex;
  flex-direction: row;
  padding: 20px;
}

.sliders-container {
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  width: 80%;
  background-color: #D9CDBF;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  /* overflow-y: auto; */
}

.sliders-container h2 {
  margin-bottom: 20px;
  color: #605746;
  text-align: center;
}

.slider-item {
  margin-bottom: 20px;
}

.slider-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.slider-label {
  margin-bottom: 5px;
  color: #605746;
  font-weight: bold;
}

.slider {
  width: calc(100% - 20px);
  -webkit-appearance: none;
  appearance: none;
  height: 8px;
  border-radius: 5px;
  background: linear-gradient(to right, #8BC34A 0%, #8BC34A 10%, #f1d0d0 10%, #f1d0d0 100%);
  outline: none;
  margin: 0;
  padding: 0;
}

.slider::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #605746;
  cursor: pointer;
  margin-top: -6px;
}

.slider::-moz-range-thumb {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background: #605746;
  cursor: pointer;
}

.slider-value {
  margin-top: 5px;
  color: #605746;
}

.calculate-button {
  background-color: #605746;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  width: 100%;
  text-align: center;
  transition: background-color 0.3s;
}

.calculate-button:hover {
  background-color: #4a4038;
}

.table-container {
  position: relative; /* Change position to relative */
  width: 100%;
  max-height: calc(100vh - 200px); /* Adjust max-height based on your layout */
  overflow: auto; /* Enable scrolling */
}

.table-wrapper {
  overflow-x: auto; /* Enable horizontal scrolling */
}

.scroll-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed; /* Ensure consistent column widths */
}

/* Add this CSS to make the table header sticky */
.scroll-table thead th {
  position: sticky;
  top: 0;
  z-index: 1; /* Ensure the header stays above the content */
  background-color: #e5e5e5;
}

/* Add this CSS to hide the scrollbar when it's not needed */
.table-container::-webkit-scrollbar {
  width: 0; /* Hide scrollbar */
  height: 0;
}

/* Update the styles for dark mode */

/* Add more specific styles for other components if needed */

.additional-info-modal {
  position: absolute;
  top: 10%; /* Adjust the top position as needed */
  left: 50%; /* Center horizontally */
  transform: translateX(-50%);
  background-color: #fff;
  border: 1px solid #ccc;
  padding: 20px;
  z-index: 9999; /* Ensure it's above other elements */
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.additional-info-modal h2 {
  font-size: 18px;
  margin-bottom: 10px;
}

.additional-info-modal p {
  font-size: 14px;
  line-height: 1.5;
}

.dropdownMenu {
  text-align: center;
  margin: 20px auto;
  max-width: 300px;
}

.dropdownMenu select {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  color: #333;
  font-size: 16px;
}

.info-button {
  position: absolute;
  bottom: 0;
  right: 0;
  border-radius: 100%;
  /* background: none; */
  /* border: none; */
  /* padding: 0; */
  /* background-color: #000; */
  margin: 5px;
  cursor: pointer;
  font-size: 12px; /* Adjust the font size as needed */
  color: rgb(86, 86, 162);
}



@media screen and (max-width: 768px) {
  .flex-container {
    flex-direction: column;
    align-items: center;
    padding: 10px;
  }

  .table-container {
    width: 100%;
    margin-left: 10px; /* Shift the table towards the right */
    /* border-radius: 10px; */
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-height: calc(100vh - 50px); /* Set maximum height (minus header height) */
    overflow: auto; /* Enable vertical scrolling */
    position: relative; /* Add relative positioning */
  }

  .dark-mode .table-container {
    width: 100%;
    margin-left: 10px; /* Shift the table towards the right */
    /* border-radius: 10px; */
    background-color: #252422;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    max-height: calc(100vh - 50px); /* Set maximum height (minus header height) */
    overflow: auto; /* Enable vertical scrolling */
    position: relative; /* Add relative positioning */
  }

  .table-container table {
    width: 100%;
  }

  .table-container thead {
    position: sticky; /* Make the header sticky */
    top: 0;
    background-color: #FBFBFC; 
    z-index: 1; /* Ensure the header stays above content */
    color: #252422
  }

  .table-container th,
  .table-container td {
    padding: 8px;
    border: 1px solid #F5F5F5;
    font-size: 14px;
    color: #333;
    text-align: left;
  }

  .table-containerblur{
    filter: blur(3px);
  }

  .dark-mode .table-container th,
  .dark-mode .table-container td {
    padding: 8px;
    /* border: 1px solid #F5F5F5; */
    background-color: #252422;
    font-size: 14px;
    color: #F5F5F5;
    text-align: left;
  }

  .dark-mode .scroll-table tr{
    padding: 8px;
    /* border: 1px solid #F5F5F5; */
    background-color: #151414;
    font-size: 14px;
    color: #F5F5F5;
    text-align: left;  }

  .dark-mode .scroll-table th{
    background-color: #151414;
  }

  .table-container th:nth-child(3), /* Adjust the index according to your table structure */
  .table-container td:nth-child(3) {
    /* Increase the width of the first column */
    width: 40%; /* Adjust the width as needed */
  }


  .slider-label {
    text-align: center;
  }

  .slider {
    width: calc(100% - 20px);
  }

  .show-sliders-mobile {
    position: absolute;
    left: 0;
    width: 30px;
    height: 90vh;
    top: 184px;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #D9CDBF;
    border-radius: 8px;
    z-index: 9999; /* Ensure it's above other elements */
  }

  .show-sliders-mobile button {
    /* width: 100%; */
    /* height: 100%; */
    background: none;
    /* color: #201B17; */
    border: none;
    padding: 0;
    margin: 0;
    cursor: pointer;
    /* transform: rotate(-90deg);  */
  }

  .button-text {
    width: 720px;
    height: 35px;
    white-space: nowrap; 
    transform: rotate(-90deg); 
    align-self: center;
    font-size: large;
    /* color: #fff; */
  }

  .show-sliders-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(232, 26, 26, 0.5); /* Semi-transparent background */
    z-index: 9998; /* Ensure it's above other elements except sliders */

    filter: blur(5px); /* Apply blur effect to the background */
    
  }

  /* Add this CSS to handle slider animation */
  .sliders-container {
    position: fixed;
    top: 5px;
    left: calc(55% - 10px); /* Adjust the left position to be 25% of the viewport width */
    width: 75%; /* Set the width to cover 75% of the viewport */
    /* height: 70vh;  */
    background-color: #D9CDBF;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    transition: left 0.5s ease; /* Smooth transition */
    z-index: 9999;
    overflow-y: auto; /* Enable vertical scrolling if needed */
  }
  

  .sliders-container.show {
    left: calc(40% - 10px);
    top: 175px; /* Adjust the position as needed */
    /* padding: 40px; */
    padding-left: 40px;
    border-color: rgba(3, 1, 1, 0.1);
  }
  .table-container-blur {
    background-color: black; /* Apply blur effect to the table */
  }

  .submit-button {
    background-color: #7C6C5D;    
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    margin-bottom: 20px;
    cursor: pointer;
    width: 100%;
    text-align: center;
    margin-top: 10px;

  }

  .backButton {
    /* background-color: #7C6C5D;    */
    /* Adjust the left position to be 25% of the viewport width */
    /* /* width: 100% */
    border-radius: 10px;
    border:none
  } 

  .search-bar {
    display: flex;
    justify-content: center; 
    align-items: center;
    border-radius: 15px;
    /* padding-left: 50px; */
    height: 25px;
    width: 90%;
    margin-left: calc(5% - 7px);
    margin-bottom: 5%;
    border-color: #fff;
    align-content: center;
    /* border: 1px solid #000; */
    box-shadow: 0 0 0 0.5px #323131;
  }

  .search-bar::placeholder {
    text-align: center;
  }

  .sliderarrow{
    width: 20px;
    background: none;
    background-color: none;
  }

  .sliderArrowDiv{
    background: none;
  }


  
}

@media screen and (min-width: 769px) {
  

.table-container {
  width: 60%;
  margin-left: 36%;
  /* margin: 20px auto; */
  max-height: calc(100vh - 200px);
  overflow: auto;
  position: relative;
  background-color: #fff;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
  padding: 10px;
}

.dark-mode .table-container {
  width: 60%;
  margin-left: 36%;
  /* margin:  20px auto; */
  max-height: calc(100vh - 200px);
  overflow: auto;
  position: relative;
  background-color: #282525;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); 
  padding: 10px;
  color: #fdf7f7;

}

/* Table */
.scroll-table {
  width: 100%;
  border-collapse: collapse;
  table-layout: fixed;
  /* border: 1px solid #ccc;  */

}

.scroll-table thead {
  /* position: sticky; */
  /* border-radius: 5px; */
  border-radius: 10px 10px 0 0;

}


/* Table header */
.scroll-table thead th {
  position: sticky;
  top: 0;
  z-index: 1;
  background-color: #D9D9D9;
  /* border: 1px solid #ccc; */
  color: #282525;
  text-align: center;
  border-radius: 10px 10px 0 0;

}


.dark-mode .scroll-table thead th{
  background-color: #151414;
  color: #ccc;
}


/* Table cells */
.scroll-table th {
  padding: 8px;

}


.dark-mode .scroll-table td{
  background-color: #282525;
}


.scroll-table td {
  padding: 10px;
  border: 1.5px solid #D9D9D9; 
}


.table-container th:nth-child(3), /* Adjust the index according to your table structure */
  .table-container td:nth-child(3) {
    /* Increase the width of the first column */
    width: 40%; /* Adjust the width as needed */
  }


/* Search bar */
.search-bar {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 15px;
  margin: 20px auto; /* Centering the search bar */
  border: 1px solid #ccc; /* Adding border to the search bar */
  padding: 10px;
  width: 80%; /* Adjusting width */
}

.search-bar input[type="text"] {
  width: 100%;
  padding: 8px;
  border-radius: 5px;
  border: none;
  font-size: 16px;
  box-sizing: border-box;
  outline: none; 
}


/* Submit button */
.submit-button {
  background-color: #7C6C5D;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  width: 100%;
  text-align: center;
  margin-top: 10px;
}

/* Show sliders button for desktop */
.show-sliders-desktop {
  position: fixed;
  right: 20px; /* Adjust the position as needed */
  bottom: 20px; /* Adjust the position as needed */
  z-index: 9999; /* Ensure it's above other elements */
}

.show-sliders-desktop button {
  background-color: #605746;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.show-sliders-desktop button:hover {
  background-color: #4a4038;
}

.sliders-content{
  position: fixed;
  margin-left: 20px;
  width: 30%;
  overflow-y: auto;
  scrollbar-width: none;
  height: 72vh;
  padding-top: 10px;
  margin-top: 2px;
  /* transform: translateX(-50%); */
  /* border-color: #000; */
  /* border: #000; */
  background-color: #D9CDBF;

}


.dark-mode .sliders-content {
  background-color: #282525;
  /* color: #ccc; */
}

.dark-mode .slider-label{
  color: #ccc;
}

.sliders-container {
  /* position: fixed; */
  top: 100px; /* Adjust the top position as needed */
  left: 50%; /* Center the sliders horizontally */
  transform: translateX(-50%);
  width: 40%; 
  height: 75vh; 
  background-color: #D9CDBF;
  border-radius: 15px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: left 1s ease; /* Smooth transition */
  z-index: 9999;
  overflow-y: auto; /* Enable vertical scrolling if needed */
  scrollbar-width: none; /* Hide the scrollbar for Firefox */

}

.sliders-container.show {
  left: 50%; /* Center the sliders horizontally */
  transform: translateX(-50%);
  top: 150px; /* Adjust the top position as needed */

}

.sliders-overlay{
  backdrop-filter: blur(10px); /* Adjust the blur radius as needed */

}

.show-sliders-mobile {
  position: fixed;
  /* position: center; */
  margin-left: calc(35% - 26%);
  width: 80%;
  height: 30px;
  top: 122px;
  display: flex;
  align-items: center;
  justify-content: center;
  /* background-color: #D9CDBF; */
  border-radius: 15px;
  z-index: 9999; /* Ensure it's above other elements */
}

.show-sliders-mobile button {
  width: 80%;
  background-color: #D9CDBF;
  border-radius: 8px;
  /* border-color: none; */
  border: none; /* Remove border */

}

.backButton {
  /* background-color: #7C6C5D;    */
  /* Adjust the left position to be 25% of the viewport width */
  /* /* width: 100% */
  border-radius: 10px;
  border:none
} 

.navbar {
  margin-left: auto; /* Aligns navbar to the right */
}

.navbar ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  overflow: hidden;
}

.navbar li {
  float: right; /* Aligns list items to the right */
}

.navbar li a {
  display: block;
  color: #000;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
}

.navbar li a:hover {
  /* background-color: #b6b0b0; */
  text-decoration: underline;
}

.toggleButton {
  margin-left: auto; /* Aligns the dark mode toggle to the right */
  color: #fff;
  cursor: pointer;
}




/* .overall-rankings {
  backdrop-filter: blur(); 
} */

}