.chart-container {
    width: 800px; /* Adjust the width as needed */
    height: 400px; /* Adjust the height as needed */
  }
  
  /* Apply styles to the Line component inside the ChartComponent */
  .chart-container .chartjs-render-monitor {
    width: 100%;
    height: 100%;
  }

  @media screen and (max-width: 768px) {

    .chart-container {
        width: 100%; /* Adjust the width as needed */
        height: 100%; /* Adjust the height as needed */
      }
      
      /* Apply styles to the Line component inside the ChartComponent */
      .chart-container .chartjs-render-monitor {
        width: 100%;
        height: 100%;
      }

  }