.methodology-container {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .methodology-heading {
    color: #333;
    font-size: 28px;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .methodology-content {
    line-height: 1.6;
  }
  
  .link {
    color: #007bff;
    text-decoration: none;
  }
  
  .link:hover {
    text-decoration: underline;
  }
  


  .dark-mode .methodology-container{
    background-color: #282525;
  }

  .dark-mode .methodology-heading {
    color: #f9f9f9;
  }