/* Light mode styles */
.App {
  text-align: center;
}

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
} */

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Dark mode styles */

/* .dark-mode {
  background-color: #000; 
  color: #fff; 
} */

/* .dark-mode .app-container {
  background-color: #000; 
}

.dark-mode .app-header {
  background-color: #000; 
  color: #fff; 
} */

.app-header .toggleButton {
  position: absolute; 
  top: 3%; 
  right: 2%; 
  z-index: 999; 
  background: none;
  /* border: none; */
  border-color: black;
}

.mobile-menu {
  display: none; 
}

.hamburger-icon {
  display: none;
}

.hamburger-icon .fa-bars {
  font-size: 24px; 
  color: #000; 
}

.hamburger-icon .fa-bars:hover {
  color: #333; 
}



.menu {
  background-color: #fff;
  
}

.dark-mode .menu{
  background-color: #282525;
}

.menu ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.dark-mode .menu li a{
  color: #f9f9f9;
}

.menu li {
  padding: 10px 20px;
}

.menu li a {
  text-decoration: none;
  color: #000;
}

.menu li a:hover {
  background-color: #f5f5f5; 
}






@media screen and (max-width: 768px) {
  .app-header .toggleButton {
    position: absolute; 
    top: 3%;
    right: 6%; 
    z-index: 999; 
    
  }


  .desktop-menu {
    display: none; 
  }

  .mobile-menu {
    display: block; 
    

  }

  .hamburger-icon {
    display: block;
    position: absolute;
    right: 3%; 
    top: 0;
    cursor: pointer;
  }
}

